<template>
  <el-row class="introduce-wrapper">
    <el-col :span="10" :offset="7">
      <h3 class="h3">{{ $t("L_ABOUT.NAV_0001") }} </h3>
      <p class="p1"> {{ $t("L_ABOUT.CONTENT_0001") }}</p>
      <p class="p2">
        {{ $t("L_ABOUT.CONTENT_0002") }}
      </p>
      <p class="p3">
        {{ $t("L_ABOUT.CONTENT_0003") }}
      </p>
      <p class="p4">{{ $t("L_ABOUT.CONTENT_0004") }}</p>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "introduce"
}
</script>

<style scoped>

</style>